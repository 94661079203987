import { AddressDocument, addressSchema } from '@models/address';
import { shipmentSchema } from 'models/product';
import { z } from 'zod';

interface Package {
  package_code: string;
  weight: {
    value: number;
    unit: string;
  };
  dimensions: {
    unit: string;
    length: number;
    width: number;
    height: number;
  };
  insured_value: {
    currency: string;
    amount: number;
  };
  label_messages: {
    reference1: string;
    reference2: string;
    reference3: string;
  };
  external_package_id: string;
}

class Customs {
  contents = 'merchandise';
  non_delivery = 'return_to_sender';
  customs_items: [
    {
      quantity: number;
      value: {
        currency: 'usd';
        amount: number;
        sku: string; //product_id
      };
      harmonized_tariff_code: '9506.99' | '8714.10'; //gear | parts
      description: string; //subcategory
    }
  ];
  constructor(
    id: string,
    amount: number,
    quantity: number,
    category: string,
    description: string
  ) {
    this.customs_items = [
      {
        quantity,
        value: {
          currency: 'usd',
          amount,
          sku: id,
        },
        harmonized_tariff_code:
          category === 'Bike Parts' ? '8714.10' : '9506.99',
        description,
      },
    ];
  }
}

export const getEstimatesArgsSchema = z.object({
  product_id: z.string().min(20),
  to_country_code: z.string().length(2),
  to_postal_code: z.string().min(4),
});

export type getEstimatesArgs = z.infer<typeof getEstimatesArgsSchema>;

export const getRatesArgsSchema = z
  .object({
    product_id: z.string().min(20).optional(),
    shipment: shipmentSchema.optional().nullable(),
    ship_to: addressSchema,
    offer_price: z.number().optional(),
    customs_info: z
      .object({
        product_title: z.string(),
        product_sku: z.string(),
        product_category: z.string(),
        product_price: z.number(),
      })
      .optional(),
  })
  .refine((args) => {
    // either product or shipment is required
    return args.product_id || args.shipment;
  }, 'Either product_id or shipment is required');

export type getRatesArgs = z.infer<typeof getRatesArgsSchema>;

export type RateResponse = {
  rate_response: {
    rates: Rate[];
    invalid_rates: Rate[];
    rate_request_id: string;
    shipment_id: string;
    created_at: string;
    status: string;
    errors: any[];
  };
  shipment_id: string;
  carrier_id: string;
  service_code: string;
  external_shipment_id: string;
  ship_date: string;
  created_at: string;
  modified_at: string;
  shipment_status: string;
  ship_to: AddressDocument;
  ship_from: AddressDocument;
  warehouse_id: null;
  return_to: AddressDocument;
  confirmation: string;
  customs: Customs | null;
  external_order_id: string;
  order_source_code: string;
  advanced_options: { [key: string]: boolean | null };
  insurance_provider: string;
  tags: any[];
  packages: Package[];
  total_weight: {
    value: number;
    unit: string;
  };
  items: any[];
};

export type Rate = {
  rate_id: string;
  rate_type: {
    Shipment: 'shipment';
  };
  carrier_id: string;
  total_amount: number;
  shipping_amount: {
    currency: string;
    amount: number;
  };
  insurance_amount: {
    currency: string;
    amount: number;
  };
  confirmation_amount: {
    currency: string;
    amount: number;
  };
  other_amount: {
    currency: string;
    amount: number;
  };
  zone: number;
  packageType?: string;
  package_type: string;
  delivery_days: number | null;
  guaranteed_service: boolean;
  estimated_delivery_date: string;
  carrier_delivery_days: string;
  ship_date: string;
  negotiated_rate: boolean;
  service_type: string;
  service_code: string;
  trackable: boolean;
  carrier_code: string;
  carrier_nickname: string;
  carrier_friendly_name: string;
  validation_status: {
    HasWarnings: 'has_warnings';
    Valid: 'valid';
  };
  warning_messages: string[];
  error_messages: any[];
};

export interface TrackPackageResponse {
  tracking_number: string;
  status_code: string; //AC, IT, DE, EX, UN, AT, NY
  status_description: string;
  carrier_status_code: string;
  carrier_status_description: string;
  ship_date: string;
  estimated_delivery_date: string;
  actual_delivery_date: string;
  exception_description: string;
  events: ShipEngineEvent[];
}

export interface ShipEngineEvent {
  occurred_at: string;
  carrier_occurred_at: string;
  carrier_status_code: string;
  description: string;
  city_locality: string;
  state_province: string;
  postal_code: string;
  country_code: string;
  company_name: string;
  signer: string;
  event_code: string;
  latitude: number;
  longitude: number;
}

export interface ShipEngineAddress {
  name: string;
  phone: string;
  email: string;
  company_name: string;
  address_line1: string;
  address_line2: string;
  address_line3: string;
  city_locality: string;
  state_province: string;
  postal_code: string;
  country_code: string;
  address_residential_indicator: string;
}

export type ShipEngineAddressValidationStatus =
  | 'unverified'
  | 'verified'
  | 'warning'
  | 'error';
export interface ShipEngineValidateResponse {
  status: ShipEngineAddressValidationStatus;
  original_address: ShipEngineAddress;
  matched_address: ShipEngineAddress;
  messages: {
    code: string;
    message: string;
    type: 'error' | 'warning' | 'info';
    detail_code: string;
  }[];
}

export interface getLabelArgs {
  uid: string;
  rate_id: string;
  address: AddressDocument;
  email?: string;
  buyer_id?: string;
  first?: string;
  order_id?: string;
  order_num?: number;
  insurance_amount?: number;
  pi_id?: string;
}
