import { FedExIcon, USPSIcon } from '@c/icons';
import { UPS } from '@c/icons/carriers';
import SafeImage from '@ui/SafeImage';
import { ProductDocument } from '@models/product';
import { Rate } from '@util/firestore/shipengine';
import { useRates } from '@util/hooks/useRates';
import { formatCurrency, isMobile } from '@util/index';
import { AddressDocument } from '@models/address';
import { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';

function ItemShippingSelect({
  product,
  shipment,
  shipTo,
  hideProduct = false,
  chooseCheapestByDefault = false,
  hide = false,
  onOptionSelected,
  selectedRateId,
  offer_amount,
  customsInfo,
  onError,
  onRatesLoaded,
}: {
  product?: ProductDocument;
  shipment?: ProductDocument['shipment'];
  shipTo: AddressDocument;
  hideProduct?: boolean;
  chooseCheapestByDefault?: boolean;
  hide?: boolean;
  onOptionSelected?: ({
    rate,
    product_id,
    seller_id,
  }: {
    rate: Rate;
    product_id: string;
    seller_id: string;
  }) => void;
  selectedRateId: string | null;
  offer_amount?: number;
  customsInfo?: {
    product_title: string;
    product_category: string;
    product_sku: string;
    product_price: number;
  };
  onError?: (error: Error) => void;
  onRatesLoaded?: () => void;
}) {
  const {
    allRates,
    rates,
    ratesError,
    ratesLoading,
    selectOption,
    foundRate,
    showAllRates,
    getFlatRateShipping,
    isPopularRate,
  } = useRates({
    product,
    shipment,
    shipTo,
    chooseCheapestByDefault,
    offer_amount,
    customsInfo,
    onOptionSelected,
    selectedRateId,
  });

  const [showShippingList, setShowShippingList] = useState(true);
  const pathname = usePathname();

  const isCheckout = pathname?.includes('/checkout');

  useEffect(() => {
    if (ratesError) {
      onError?.(ratesError as Error);
    }
  }, [ratesError, onError]);

  if (hide) return null;

  const handleOptionSelect = (option: Rate) => {
    const rate = allRates?.find((r) => r.rate_id === option.rate_id);

    selectOption(option);

    onOptionSelected?.({
      rate: option,
      product_id: product?.id ?? '',
      seller_id: product?.seller_id ?? '',
    });
  };

  const flatRate = getFlatRateShipping();

  useEffect(() => {
    if (rates?.length && !ratesLoading) {
      onRatesLoaded?.();
    }
  }, [rates, ratesLoading]);

  return (
    <div className={`${!showShippingList && selectedRateId && ''} h-full`}>
      {product && !hideProduct && (
        <div className={`flex items-center gap-x-4`}>
          <SafeImage
            alt={product.title}
            src={product.thumbnail}
            height={100}
            width={100}
            className="aspect-square h-32 w-32 rounded-2xl object-cover"
          />

          <div className={`flex w-full items-start justify-between`}>
            <div className="flex flex-col gap-y-2">
              <h5 className="line-clamp-1 w-[25rem] whitespace-pre-wrap text-[1.6rem] font-semibold sm:w-[28rem]">
                {product.title}
              </h5>

              <div className="flex items-center gap-x-2">
                <span className="text-elipsis line-clamp-1 text-[1.6rem] font-medium text-brand-dark-gray sm:pr-10">
                  {product.category1 === 'Boots' ||
                  product.category1 === 'Helmets'
                    ? product.category1
                    : product.category2
                    ? product.category2
                    : product.location_display
                    ? product.location_display
                    : product.category1
                    ? product.category1
                    : product.category}
                  {product.size && (
                    <>
                      {product.size?.letter
                        ? `, Size: ${product.size?.letter}`
                        : ''}
                      {product.size?.number
                        ? `${product.size?.letter ? '/ ' : ''} Size: ${
                            product.size?.number
                          }`
                        : ''}
                      {!!product.variations?.length &&
                      product.variations.length > 1
                        ? ', Size: Multiple Sizes'
                        : ''}
                    </>
                  )}
                </span>
              </div>
            </div>

            <div className="flex flex-col items-end gap-y-2">
              <p className="text-[1.6rem] font-semibold">
                {formatCurrency(product.price)}
              </p>

              <p className="text-[1.6rem] text-zinc-500">
                Qty: {product.quantity ?? 1}
              </p>
            </div>
          </div>
        </div>
      )}
      {/* options */}
      <div>
        {/* flat rate */}
        {product?.is_flat_rate === false || shipment ? (
          // multiple options
          <div className="flex flex-col gap-y-4" key={product?.id}>
            {/* shipping rate options */}
            {ratesLoading && (
              <div className="mt-8 flex w-full flex-col gap-4">
                <div className="relative h-[30rem] w-full rounded-[1rem] bg-brand-lightest-gray p-12 sm:h-[55rem]">
                  <div className="absolute inset-0 flex items-center justify-center">
                    <span className="animate-pulse text-[1.8rem] font-medium text-brand-gray">
                      Loading Shipping Options...
                    </span>
                  </div>
                </div>
              </div>
            )}

            <div
              className={`${isCheckout && 'mt-8'} flex flex-col gap-y-4 ${
                // making the list bigger on mobile make an offer modal
                pathname?.includes('/product') && isMobile()
                  ? 'max-h-[90rem]'
                  : 'max-h-[55rem]'
              }`}
            >
              {!!rates?.length &&
                showShippingList &&
                [...rates]
                  .sort((a, b) => {
                    // sort unavailable options to the bottom
                    const aUnavailable =
                      a.delivery_days === null ||
                      a.carrier_delivery_days === null;

                    const bUnavailable =
                      b.delivery_days === null ||
                      b.carrier_delivery_days === null;

                    return Number(aUnavailable) - Number(bUnavailable);
                  })
                  .map((option) => {
                    const selected =
                      foundRate?.service_code === option.service_code;

                    const showOption =
                      showAllRates ||
                      isPopularRate(option) ||
                      rates.length === allRates?.length;

                    const unavailable =
                      option.delivery_days === null ||
                      option.carrier_delivery_days === null;

                    return (
                      showOption && (
                        <button
                          className={`flex w-full gap-[1.6rem] rounded-[2rem] p-6 ${
                            selected
                              ? 'border border-brand-secondary bg-white'
                              : 'bg-[#F6F6F6]'
                          }`}
                          type="button"
                          onClick={() => {
                            if (!unavailable) {
                              handleOptionSelect(option);
                            }
                          }}
                          key={option.rate_id}
                        >
                          <div className="w-[6rem]">
                            {option.carrier_code === 'fedex' ? (
                              <FedExIcon className="h-16 w-16" />
                            ) : option.carrier_code === 'ups' ? (
                              <UPS className="h-16 w-16" />
                            ) : (
                              <USPSIcon className="h-16 w-16" />
                            )}
                          </div>

                          <div className="flex grow items-center gap-5">
                            <div className="flex flex-col items-start">
                              {unavailable ? (
                                <h5 className="text-[1.6rem] font-semibold text-brand-secondary">
                                  Estimative Unavailable
                                </h5>
                              ) : (
                                <h5 className="text-[1.6rem] font-semibold text-brand-secondary">
                                  {option.delivery_days}{' '}
                                  {option.delivery_days === 1 ? 'Day' : 'Days'}
                                </h5>
                              )}

                              <p className="text-[1.4rem] text-brand-gray">
                                {option.service_type}
                              </p>
                            </div>

                            <div className="ml-auto text-[1.6rem] font-semibold text-black">
                              {formatCurrency(option.total_amount)}
                            </div>
                          </div>
                        </button>
                      )
                    );
                  })}
            </div>

            {/* no shipping rates found */}
            {(ratesError as Error) && !ratesLoading && (
              <div className="text-center text-brand-red">
                {(ratesError as Error).message}
              </div>
            )}

            {/* {showSelectShippingButton && (
              <div
                className="w-full"
                onClick={() => {
                  setShowShippingList(true);
                }}
              >
                <div className="flex w-full items-center justify-between rounded-2xl border-2 border-dashed border-zinc-300 p-6 text-[1.8rem]">
                  <h3 className="font-semibold text-black">Select shipping</h3>

                  <PlusCircleIcon
                    fill="#C41719"
                    className="aspect-square h-12 w-12 rounded-full "
                  />
                </div>
              </div>
            )} */}
          </div>
        ) : (
          <div className="mt-8 flex w-full items-center justify-between rounded-2xl bg-brand-off-white p-4">
            {flatRate === null ? (
              <div>
                <h5 className=" text-[1.6rem] font-semibold">
                  Error: No Shipping Available
                </h5>
                <p className="text-[1.4rem] text-brand-gray">
                  Please contact the seller to add shipping to your country
                </p>
              </div>
            ) : (
              <>
                <div>
                  <h5 className="text-[1.6rem] font-semibold text-brand-secondary">
                    Flat Rate Shipping
                  </h5>
                  <p className="text-[1.4rem] text-brand-gray">
                    Shipping carrier selected by seller
                  </p>
                </div>
                <div className="text-[1.6rem] font-semibold">{flatRate}</div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ItemShippingSelect;
