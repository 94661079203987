import { PaymentDocument } from '@models/payment';
import { type Token } from '@stripe/stripe-js';
import { FirebaseCallable, db, functions, httpsCallable } from '@util/firebase';
import Stripe from 'stripe';
import {
  CreatePaymentArgs,
  ExternalAccount,
  PayoutResponse,
  StripeAccountResponse,
  StripeTransaction,
  UpdatePaymentArgs,
} from './payments.types';
import { TransferObject } from 'models/order';
import { collection, doc, updateDoc } from 'firebase/firestore';

interface PaymentIntent {
  data: string;
  pi_id: string;
}

export async function createPayment(
  args: CreatePaymentArgs
): Promise<PaymentIntent> {
  const res = await httpsCallable<CreatePaymentArgs, PaymentIntent>(
    functions,
    FirebaseCallable.createPaymentIntent
  )(args);
  return res.data;
}

export async function updatePayment(
  args: UpdatePaymentArgs
): Promise<PaymentIntent> {
  const res = await httpsCallable<UpdatePaymentArgs, PaymentIntent>(
    functions,
    FirebaseCallable.updatePaymentIntent
  )(args);
  return res.data;
}

type PayWithBalanceArgs = Omit<UpdatePaymentArgs, 'pi_id'>;
export async function payWithBalance(
  args: PayWithBalanceArgs
): Promise<Stripe.Response<Stripe.Charge>> {
  const res = await httpsCallable<
    PayWithBalanceArgs,
    Stripe.Response<Stripe.Charge>
  >(
    functions,
    FirebaseCallable.payWithBalance
  )(args);
  return res.data;
}

export async function createSetupIntent(
  is_payout = false
): Promise<PaymentIntent> {
  const res = await httpsCallable<{}, PaymentIntent>(
    functions,
    FirebaseCallable.createSetupIntent
  )({ is_payout });
  return res.data;
}

export async function getBankToken(payment_method: string): Promise<Token> {
  const res = await httpsCallable<{ payment_method: string }, Token>(
    functions,
    FirebaseCallable.getBankToken
  )({ payment_method });
  return res.data;
}

// can pass in uid if admin
// uid?: string
export async function getPaymentMethods(): Promise<PaymentDocument[]> {
  const res = await httpsCallable<{}, any>(
    functions,
    FirebaseCallable.getPaymentMethods
  )({});
  const cards: PaymentDocument[] = res.data.data.map((pm: any) => ({
    id: pm.id,
    last4: pm.card.last4,
    brand: pm.card.brand,
    name: pm.billing_details.name,
    expMonth: `${pm.card.exp_month}`,
    expYear: `${pm.card.exp_year}`,
  }));
  return cards;
}

export async function saveCard(
  payment_method: string,
  customer_id: string
): Promise<string> {
  const { data } = await httpsCallable<{}, { data: string }>(
    functions,
    FirebaseCallable.saveCard
  )({ payment_method, customer_id });
  return data.data;
}

export async function createCustomer(payment_method: string): Promise<string> {
  const { data } = await httpsCallable<{}, { data: string }>(
    functions,
    FirebaseCallable.createCustomer
  )({ payment_method });
  return data.data;
}

export async function deleteCard(pm_id: string): Promise<any> {
  const res = await httpsCallable<{}, any>(
    functions,
    FirebaseCallable.deleteCard
  )({
    payment_method: pm_id,
  });
  return res.data;
}

export async function getBalance() {
  const res = await httpsCallable<
    {},
    { available_balance: number; actual_pending: number; currency: string }
  >(
    functions,
    FirebaseCallable.getBalance
  )({});
  return res.data;
}

export async function getTransactionHistory() {
  const res = await httpsCallable<{}, { data: StripeTransaction[] }>(
    functions,
    // 'listTransactions'
    FirebaseCallable.listTransactions
  )({});
  return res.data?.data ?? [];
}

export async function payoutSeller(
  amount: number,
  method: 'standard' | 'instant',
  destination: string,
  currency = 'usd',
  requirements?: { [x: string]: { [x: string]: string } }
): Promise<PayoutResponse> {
  const res = await httpsCallable<{}, PayoutResponse>(
    functions,
    // 'payoutSeller'
    FirebaseCallable.payoutSeller
  )({
    amount,
    method,
    destination,
    currency,
    requirements,
  });
  return res.data;
}

export async function getStripeAccount(uid?: string) {
  const res = await httpsCallable<{ uid?: string }, StripeAccountResponse>(
    functions,
    // 'getSeller'
    FirebaseCallable.getSeller
  )({
    uid,
  });
  return res.data;
}

export async function getStripeCustomer(uid?: string) {
  const res = await httpsCallable<{ uid?: string }, any>(
    functions,
    // 'getCustomer'
    FirebaseCallable.getCustomer
  )({ uid });
  return res.data;
}

export async function updateSellerAccount(
  requirements: Record<string, string>[]
) {
  const res = await httpsCallable<{}, { status: boolean }>(
    functions,
    // 'updateStripeAccount'
    FirebaseCallable.updateStripeAccount
  )({
    requirements,
  });
  return res.data;
}

export async function addSellerPayoutMethod(account_id: string, id: string) {
  const res = await httpsCallable<{}, ExternalAccount>(
    functions,
    FirebaseCallable.addSellerPayoutMethod
  )({ account_id, id });
  return res.data;
}

export async function removeSellerPayoutMethod(account_id: string, id: string) {
  const res = await httpsCallable<{}, ExternalAccount>(
    functions,
    FirebaseCallable.removeExternalAccount
  )({ account_id, id });
  return res.data;
}

export async function chargeAccount(args: {
  amount: number;
  account_id: string;
  description?: string;
  customer?: string;
  order_id?: string;
  order_num?: number;
}) {
  const res = await httpsCallable<typeof args, string>(
    functions,
    FirebaseCallable.chargeAccount
  )(args);
  // returns the charge_id
  return res.data;
}

export async function chargeUnpaidOrder(
  order_num: number,
  payment_method: string
) {
  const res = await httpsCallable<
    { order_num: number; payment_method: string },
    { id: string }
  >(
    functions,
    FirebaseCallable.chargeUnpaidOrder
  )({ order_num, payment_method });
  return res.data;
}

export async function createTransfer(
  account_id: string,
  amount: number,
  description = ''
) {
  const res = await httpsCallable<{}, string>(
    functions,
    FirebaseCallable.createTransfer
  )({ account_id, amount, description });
  // returns the charge_id
  return res.data;
}

export async function createTransferOrder(transfer: TransferObject) {
  await httpsCallable<TransferObject, void>(
    functions,
    FirebaseCallable.createTransferOrder
  )(transfer);
}

export async function deleteTransfer(transferId: string) {
  // set status to canceled
  const colRef = collection(db, 'manual_transfers');
  const docRef = doc(colRef, transferId);
  await updateDoc(docRef, {
    status: 'canceled',
  });
}
// type Status =
//   | 'canceled'
//   | 'processing'
//   | 'requires_action'
//   | 'requires_capture'
//   | 'requires_confirmation'
//   | 'requires_payment_method'
//   | 'succeeded'
//   | 'failed'
// | 'pending';
export async function getPaymentInfo(payment_id: string) {
  const res = await httpsCallable<{}, Stripe.PaymentIntent>(
    functions,
    FirebaseCallable.getPaymentInfo
  )({ payment_id });
  return res.data;
}

export const createOrderByCharge = async (charge_id: string) => {
  const res = await httpsCallable<{}, string>(
    functions,
    FirebaseCallable.createOrderByCharge
  )({ charge_id });
  return res.data; // order id
};
