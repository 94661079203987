import { cva, VariantProps } from 'class-variance-authority';

const styles = cva(
  'rounded-full h-[4.8rem] w-[4.8rem] flex flex-col items-center justify-center mx-[0.8rem] transition-all duration-250',
  {
    variants: {
      selected: {
        true: 'bg-brand-secondary text-brand-white',
        false:
          'bg-brand-lightest-gray text-brand-gray border-[4px] border-brand-lighter-gray',
      },
    },
    defaultVariants: {
      selected: false,
    },
  }
);

interface StepProps extends VariantProps<typeof styles> {
  id: string;
  icon: React.ReactNode;
  selected: boolean;
  label?: string;
}

const Step = ({ id, icon, selected, label }: StepProps) => {
  return (
    <div className="z-10 flex flex-col items-center gap-[1.6rem]">
      <div id={id} className={styles({ selected })}>
        {icon}
      </div>
      {label && (
        <span className="hidden whitespace-nowrap text-[1.5rem] font-medium  leading-[2.2rem] text-brand-lightest-black sm:inline">
          {label}
        </span>
      )}
    </div>
  );
};

export default Step;
