import React from 'react';
import Step from './Step';

interface StepperProps {
  steps: any[];
  currentStep: number;
}
const Stepper = ({ steps, currentStep }: StepperProps) => {
  const isSelected = (index: number) => {
    return index === currentStep || isBeforeSelected(index);
  };
  const isBeforeSelected = (index: number) => {
    return index < currentStep;
  };
  return (
    <div className="flex w-full flex-col gap-[1.6rem]">
      <div id="stepper" className="relative flex justify-between">
        {steps.map((step, index) => {
          if (index === steps.length - 1) {
            return (
              <Step key={step.id} {...step} selected={isSelected(index)} />
            );
          }
          return (
            <React.Fragment key={step.id}>
              <Step
                {...step}
                selected={index === currentStep || index < currentStep}
              />

              <div className="absolute  mt-[2.2rem] h-2 w-full rounded-full bg-brand-lighter-gray dark:bg-gray-700">
                <div
                  className="h-2 rounded-full bg-brand-secondary"
                  style={{
                    width:
                      (currentStep / (steps.length - 1) +
                        (currentStep === 0 ? 0.1 : 0)) *
                        100 +
                      '%',
                  }}
                ></div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <div className="flex flex-col items-center md:hidden">
        <span className="text-[1.5rem] leading-[2.2rem] text-brand-gray">
          Status
        </span>
        <span className="font-semibold leading-[2.7rem] text-brand-light-black">
          {steps[currentStep]?.label}
        </span>
      </div>
    </div>
  );
};

export default Stepper;
