import { useQuery } from '@tanstack/react-query';
import { calculateShipping, getShippingRegion } from '@util/firestore/cart';
import { ProductDocument } from '@models/product';
import { Rate } from '@util/firestore/shipengine';
import { AddressDocument } from '@models/address';
import { useMemo, useState } from 'react';
import { formatCurrency } from '..';
import { getRates } from '../firestore/shipengine';

export const useRates = ({
  product,
  shipment,
  shipTo,
  chooseCheapestByDefault,
  offer_amount,
  customsInfo,
  onOptionSelected,
  selectedRateId,
}: {
  product?: ProductDocument;
  shipment: ProductDocument['shipment'];
  shipTo: AddressDocument;
  chooseCheapestByDefault: boolean;
  offer_amount?: number;
  customsInfo?: {
    product_title: string;
    product_category: string;
    product_sku: string;
    product_price: number;
  };
  onOptionSelected?: ({
    rate,
    product_id,
    seller_id,
  }: {
    rate: Rate;
    product_id: string;
    seller_id: string;
  }) => void;
  selectedRateId: string | null;
}) => {
  const [showAllRates, setShowAllRates] = useState<boolean>(false);

  const {
    data: allRates,
    isLoading: ratesLoading,
    error: ratesError,
  } = useQuery({
    queryKey: [
      'rates',
      product?.id,
      shipment?.ship_from.address_line1,
      shipTo.address_line1,
      shipment?.package.insured_value?.amount,
      shipment?.package.weight?.value,
      shipment?.package.dimensions?.height,
      shipment?.package.dimensions?.width,
      shipment?.package.dimensions?.length,
      offer_amount,
    ],
    queryFn: () => getRatesSorted(shipTo, shipment, product?.id, offer_amount),
    staleTime: 1000 * 60, // 1 hour
    enabled: product?.is_flat_rate === false || !!shipment,
    refetchOnWindowFocus: false,
  });

  // partition the rates into popular and rest
  const [popular, rest] = useMemo(() => {
    return (
      allRates?.reduce(
        (acc, rate) => {
          if (isPopularRate(rate)) {
            acc[0].push(rate);
          } else {
            acc[1].push(rate);
          }
          return acc;
        },
        [[], []] as Rate[][]
      ) ?? [[], []]
    );
  }, [allRates]);

  // slice the rates to show the popular ones first, then the rest
  const rates = [...popular, ...rest];

  const selectedRate = rates?.find((r) => r.rate_id === selectedRateId);
  // find the selected rate in the rates response
  const foundRate =
    selectedRate &&
    rates?.find((r) => r.service_code === selectedRate?.service_code);

  if (rates && !selectedRate && chooseCheapestByDefault) {
    const cheapestRate = rates?.sort(
      (a, b) => a.total_amount - b.total_amount
    )[0];
    if (cheapestRate) {
      setTimeout(() => selectOption(cheapestRate), 0);
    }
  }

  function selectOption(option: Rate) {
    if (!product) return;
    onOptionSelected?.({
      rate: option,
      product_id: product.id,
      seller_id: product.seller_id,
    });
  }

  const getFlatRateShipping = () => {
    if (!product) return null;
    const cost = calculateShipping(product, getShippingRegion(shipTo))?.cost;
    return cost !== undefined ? formatCurrency(cost) : null;
  };

  function isPopularRate(rate: Rate) {
    const isInternational = rate.service_code?.includes('international');
    const service_codes = isInternational
      ? [
          'usps_priority_mail_international',
          'ups_standard_international',
          'fedex_international_economy',
          'canada_post',
        ]
      : [
          'usps_priority_mail',
          'ups_ground',
          'fedex_home_delivery',
          'ups_surepost_1_lb_or_greater',
        ];
    return service_codes.includes(rate.service_code) ? 1 : 0;
  }

  async function getRatesSorted(
    ship_to: AddressDocument,
    shipment?: ProductDocument['shipment'],
    product_id?: string,
    offer_amount?: number
  ) {
    const rates = await getRates({
      product_id,
      ship_to,
      shipment,
      offer_price: offer_amount,
      customs_info: customsInfo,
    });
    return rates.sort(
      (a, b) =>
        isPopularRate(b) - isPopularRate(a) ||
        (a.delivery_days ?? 0) - (b.delivery_days ?? 0)
    );
  }
  return {
    allRates,
    rates,
    ratesLoading,
    ratesError,
    foundRate,
    getFlatRateShipping,
    selectOption,
    showAllRates,
    setShowAllRates,
    isPopularRate,
  };
};
